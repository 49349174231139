import {
	getUserData,
	storeAuthDataClient
} from "_common/utils/user";

const StoreAuth = ( { userAuth } ) => {
	const authData = getUserData ();

	if ( ( userAuth?.userId === authData?.userId ) || !Object.keys ( authData ).length )
		storeAuthDataClient ( userAuth );

	return null;
};

export default StoreAuth;
