import Cookies    from "js-cookie";
import { v1, v5 } from "uuid";

import {
	getParamValueFromQueryString,
	updateURLParams
} from "_common/webview";
import {
	APP_VERSION,
	DEVICE_TYPE,
	NAMESPACE
} from "_config/index";

import sha256 from "./sha256";

import { checkIsBrowser } from ".";

const DEVICE_ID_KEY = "rooter_pwa_uuid";

export const USER_DATA_KEY = "user_auth";

const CDN_CACHE_ID = "CDN-CACHE-IDENTITY";

const getSHA256String = elements => {
	return sha256 ( elements.join ( "" ) );
};

const storeUserDataCookie = data => {
	const __data = {
		accessToken  : data.accessToken,
		refreshToken : data.refreshToken,
		userId       : data.userId,
		isNew        : data.isNew,
		locale       : data.locale,
		tokenType    : data.tokenType,
		isVerified   : data.isVerified,
		userRoles    : data.userRoles
	};

	if ( checkIsBrowser () ) {
		// check if we are in webview
		const isWebView = getParamValueFromQueryString ( "platform" );

		if ( isWebView ) {
			// replace url when a refresh auth tokens call happens.
			const urlParams = updateURLParams ( __data );

			if ( isWebView === "android" ) {

				window?.Android?.refreshTokens ( __data.tokenType, __data.accessToken, __data.refreshToken );
				window.location.replace ( urlParams );

				return;

			}
			if ( isWebView === "ios" ) {

				// Send a message with arguments to the native iOS code
				let dataToSend = {
					action    : "refreshTokens", // action is always name of the JS function
					arguments : {
						tokenType    : __data.tokenType,
						accessToken  : __data.accessToken,
						refreshToken : __data.refreshToken
					}
				};

				window?.webkit?.messageHandlers?.ios_handler?.postMessage ( JSON.stringify ( dataToSend ) );
				/*
          window.location.replace does not work in iOS Safari.
          This is a workaround for iOS
        */
				const fullUrl        = window.location.href;
				const currentBaseUrl = fullUrl.split ( "?" )[0];

				// Update the query params here

				window.location.href = currentBaseUrl + urlParams;

				return;

			}

		}

		storeUserData ( __data );
	}

	return {
		...__data,
		deviceId: data.deviceId
	};
};

const USER_LOGIN_REQUEST = {
	/* OLD
  pushId   : "webPushId",
  locale   : "en",
  campaign : "webCampaign" */

	locale             : "en",
	thirdPartyCampaign : "webCampaign",
	pushId             : "webPushId"
};

const getDeviceId = () => {
	if ( !checkIsBrowser () )
		return v1 ();

	// get deviceId from URL instead of countless webview checks
	const webviewDeviceId = getParamValueFromQueryString ( "deviceId" );

	if ( webviewDeviceId ) {
		return webviewDeviceId;
	} else {
		const cookieData = Cookies.get ( DEVICE_ID_KEY );

		if ( cookieData && cookieData !== "undefined" )
			return cookieData;

		return storeDeviceId ();
	}

};

const createAuthToken = data => {
	const deviceId    = getDeviceId ();
	const appSecret   = JSON.parse ( process.env.APP_SECRET_KEY );
	const appEnv      = process.env.APP_ENV;
	const __string    = [ appSecret[appEnv], deviceId, JSON.stringify ( data || USER_LOGIN_REQUEST ), appSecret[appEnv] ];
	const accessToken = getSHA256String ( __string );

	return {
		userAuth: {
			deviceId,
			accessToken
		}
	};
};

const checkFirstAndLastName = value => {
	let regName = /^[a-zA-Z]+ [a-zA-Z]+$/;

	return regName.test ( value );
};

const getUserData = () => {
	const isWebView = getParamValueFromQueryString ( "platform" );

	if ( isWebView ) {
		const data = {
			accessToken  : getParamValueFromQueryString ( "accessToken" ),
			refreshToken : getParamValueFromQueryString ( "refreshToken" ),
			userId       : getParamValueFromQueryString ( "userId" ),
			tokenType    : getParamValueFromQueryString ( "tokenType" )
		};

		return data;
	}

	const userDataFromCookies = Cookies.get ( USER_DATA_KEY );

	if ( !userDataFromCookies || typeof userDataFromCookies === "undefined" )
		return {};

	const data = JSON.parse ( userDataFromCookies );

	return data;
};

const storeDeviceId = deviceId => {
	// Creating a new uuid
	if ( !deviceId )
		deviceId = v1 ();

	Cookies.set ( DEVICE_ID_KEY, deviceId, { expires: 365, sameSite: "None", secure: true } );

	return deviceId;
};

const storeUserData = userAuth => {
	Cookies.set ( USER_DATA_KEY, JSON.stringify ( userAuth ), { expires: 365, sameSite: "None", secure: true } );

	return userAuth;
};

const storeCacheId = ( userId, isMobile ) => {
	// This is for CDN, to identify which cached data to use,
	// or create a new one if no match

	const uniqueKey = userId || "";
	const namespace = NAMESPACE[isMobile ? "mobile" : "desktop"];

	const cacheKey = v5 ( String ( uniqueKey ), namespace );

	Cookies.set ( CDN_CACHE_ID, cacheKey, { expires: 1, sameSite: "None", secure: true } );

	return;
};

const clearUserData = () => {
	// Need to clear device id as well
	// right now backend is mapping user with this id
	Cookies.remove ( DEVICE_ID_KEY );
	Cookies.remove ( USER_DATA_KEY );
	Cookies.remove ( CDN_CACHE_ID );

	return;
};

const storeAuthDataClient = async data => {
	storeDeviceId ( data?.deviceId );
	storeUserData ( data );
};

export const getUserRolesFromCookies = () => {
	const userData = Cookies.get ( USER_DATA_KEY );

	if ( !userData )
		return;

	return JSON.parse ( userData )?.userRoles;

};

const getDeviceType = () => {
	// get deviceType from URL instead of countless webview checks
	const webviewDeviceType = getParamValueFromQueryString ( "deviceType" ) || getParamValueFromQueryString ( "platform" );

	if ( webviewDeviceType ) {
		return webviewDeviceType;
	}

	return DEVICE_TYPE;
};

const getAppVersion = () => {
	// get app version from URL instead of countless webview checks
	const webviewAppVer = getParamValueFromQueryString ( "appVersion" );

	if ( webviewAppVer ) {
		return webviewAppVer;
	}

	return APP_VERSION;
};

export {
	USER_LOGIN_REQUEST,
	checkFirstAndLastName,
	clearUserData,
	createAuthToken,
	getAppVersion,
	getDeviceId,
	getDeviceType,
	getSHA256String,
	getUserData,
	storeAuthDataClient,
	storeCacheId,
	storeUserDataCookie
};
